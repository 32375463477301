import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const MiamiFertilityLandingPage = () => (
  <PhysicianLandingPage
    physician="Mount Sinai Medical Center"
    institution="Mount Sinai Medical Center"
    referralCode="MIAMIFERTILITY"
  />
)

export default MiamiFertilityLandingPage
